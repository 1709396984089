/* eslint-disable react/jsx-no-target-blank */

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../resources/helpers";
import { RootState } from "../../../setup/redux/store";
import AsideMenuLogout from "./AsideLogout";
import { AsideMenuItem } from "./AsideMenuItem";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import AsideMenuSetting from "./AsideSetting";
import UserBalance from "./AsideUserBalance";
import UserItem from "./AsideUserItem";
import { Link, useLocation } from "react-router-dom";
import * as lc from "../../modules/localstorage/index";
import { useTheme } from "src/setup/theme/useTheme";
import {throttle } from "lodash";
import {
  collectionGroup,
  getCountFromServer,
  query,
  where,
  collection,
} from "firebase/firestore";
import { createRef, newDB } from "src/db";
import moment from "moment";
import * as Log from "src/util/SDayslogger"

export function AsideMenuMain() {
  const [isSidebarMinimized, setIsSidebarMinimized] = useState<boolean>(false);
  const currentTheme = useTheme();
  const clientID = lc.getItemLC(lc.LCName.Client)?.id;
  const { t } = useTranslation();
  const loc = useLocation();
  const [notifications, setNotifications] = useState([]);
  const [notifTrigger, setNotifTrigger] = useState(0);

  const countUnreadMessages: number = useSelector(
    (state: RootState) => state.Chat.countTotalUnreadMessages
  );
  const countAllChatUnreadMessages: number = useSelector(
    (state: RootState) => state.Chat.allChatCountTotalUnreadMessages
  );
  const chatState: string = useSelector(
    (state: RootState) => state.Chat.chatState
  );
  const countUserUnreadMessages: number = useSelector(
    (state: RootState) => state.Chat.countTotalUserUnreadMessages
  );
  const countCustomerInQueue: number = useSelector(
    (state: RootState) => state.CIQ.countCIQ
  );
  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  const [userUnreadMessages, setUserUnreadMessages] = useState<number>(0);
  const [customerInQueue, setCustomerInQueue] = useState<number>(0);
  const [ciqCount, setCiqCount] = useState<number>(0);
  const [chatCount, setChatCount] = useState<number>(0);

  useEffect(() => {
    setUnreadMessages(countUnreadMessages);
    setUserUnreadMessages(countUserUnreadMessages);
    setCustomerInQueue(countCustomerInQueue);
    if (!loc.pathname.includes("setting")) {
      const asideMenuMain = document.getElementById("aside-menu-main");
      asideMenuMain?.classList.remove("d-none");

      const asideMenuProfile = document.getElementById("aside-menu-setting");
      asideMenuProfile?.classList.add("d-none");
    }
  }, [countUnreadMessages, countCustomerInQueue, countUserUnreadMessages, loc]);

  const backtoMainClickHandler = () => {
    Log.debug("masuk di profileClickHandler");
    const asideMenuMain = document.getElementById("aside-menu-main");
    asideMenuMain?.classList.remove("d-none");

    //remove display none for menu profile component
    const asideMenuProfile = document.getElementById("aside-menu-setting");
    asideMenuProfile?.classList.add("d-none");
  };

  useEffect(() => {
    const element = document.getElementById("kt_aside");

    if (!element) return;

    const handleResize = throttle((entries: ResizeObserverEntry[]) => {
      requestAnimationFrame(() => {
        for (let entry of entries) {
          const componentWidth = entry.contentRect.width;
          Log.debug(componentWidth, "compowidth jjs");
          if (componentWidth < 100 && !isSidebarMinimized) {
            setIsSidebarMinimized(true);
          } else if (componentWidth >= 100 && isSidebarMinimized) {
            setIsSidebarMinimized(false);
          }
        }
      });
    }, 100);

    const resizeObserver = new ResizeObserver(handleResize);

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
      handleResize.cancel();
    };
  }, [isSidebarMinimized]);

  // counter bubble
  useEffect(() => {
    const calculateCountsCiq = async () => {
      Log.info("Calculate Count CIQ - Start ", Date.now())
      const today = moment().format("DD-MM-YYYY");
      const yesterday = moment().subtract(1, "days").format("DD-MM-YYYY");

      // whatsapp queue
      let countQueryWhatsappQueue = query(
        collectionGroup(newDB, "rooms"),
        where("client", "==", createRef("clients", clientID)),
        where(`accountData.type`, "==", "whatsapp"),
        where("lastCustomerInteractionAtString", "==", [yesterday, today]),
        where("status", "==", "queue")
      );
      let countWhatsappQueue = (
        await getCountFromServer(countQueryWhatsappQueue)
      ).data().count;

      // whatsapp handled
      let countQueryWhatsappHandled = query(
        collectionGroup(newDB, "rooms"),
        where("client", "==", createRef("clients", clientID)),
        where("status", "==", "handled"),
        where("accountData.type", "==", "whatsapp"),
        where("lastCustomerInteractionAtString", "==", [yesterday, today]),
        where("toUserOnline", "==", [])
      );
      let countWhatsappHandled = (
        await getCountFromServer(countQueryWhatsappHandled)
      ).data().count;

      // marketplace queue
      let countQueryMarketplaceQueue = query(
        collectionGroup(newDB, "rooms"),
        where("client", "==", createRef("clients", clientID)),
        where("status", "==", "queue"),
        where("accountData.type", "in", ["shopee", "tokopedia", "lazada"])
      );
      let countMarketplaceQueue = (
        await getCountFromServer(countQueryMarketplaceQueue)
      ).data().count;

      // marketplace handled
      let countQueryMarketplaceHandled = query(
        collectionGroup(newDB, "rooms"),
        where("client", "==", createRef("clients", clientID)),
        where("status", "==", "handled"),
        where("accountData.type", "in", ["shopee", "tokopedia", "lazada"]),
        where("toUserOnline", "==", [])
      );
      let countMarketplaceHandled = (
        await getCountFromServer(countQueryMarketplaceHandled)
      ).data().count;
      Log.info("Calculate Count CIQ - FIN ", Date.now())
      setCiqCount(
        countWhatsappQueue +
          countWhatsappHandled +
          countMarketplaceHandled +
          countMarketplaceQueue
      );
    };

    const calculateCountsMessage = async () => {
      Log.debug("Calculate Count Message - START ")
      let countQueryMessage = query(
        collection(newDB, "messages"),
        where("client", "==", createRef("clients", clientID)),
        where(`destination`, "==", "inbound"),
        where("isMessageRead", "==", false)
      );
      let countMessages = (await getCountFromServer(countQueryMessage)).data()
        .count;
      setChatCount(countMessages);
    };

    // const messageQuery = collection(newDB, "messages");
    // const unsubscribeMessage = onSnapshot(messageQuery, (snapshot) => {
    //   calculateCountsMessage();
    // });
    // const ciqQuery = collection(newDB, "collaborations");
    // const unsubscribeciq = onSnapshot(ciqQuery, (snapshot) => {
    //   calculateCountsCiq();
    // });

    Log.debug("Call calculate function") 
    calculateCountsCiq();
    calculateCountsMessage();
    Log.debug("Call calculate function - fin") 
    // return () => {
    //   unsubscribeMessage()
    //   unsubscribeciq()
    // };
  }, [loc]);

  return (
    <div
      id="aside-menu-main"
      className="bg-white d-flex flex-column justify-content-between h-100"
      key={"aside-menu-main"}
    >
      <div>
        <Link
          to="/dashboard"
          className="navbar-brand w-100 d-flex align-items-start justify-content-start p-4 ps-5 bg-white"
        >
          {isSidebarMinimized === false ? (
            <img
              alt={currentTheme.name}
              src={toAbsoluteUrl(currentTheme.urlLogoImage)}
              className="ps-0 ms-0 w-auto mw-100"
              style={{ maxHeight: "18px" }}
              onClick={() => {
                backtoMainClickHandler();
              }}
            />
          ) : (
            <img
              alt={currentTheme.name}
              src={toAbsoluteUrl(currentTheme.urlLogo)}
              className="ps-0 ms-0 w-100"
              style={{ maxHeight: "18px" }}
              onClick={() => {
                backtoMainClickHandler();
              }}
            />
          )}
        </Link>
        <AsideMenuItem
          to="/home"
          duotoneIcon="/media/icons/duotune/general/gen001.svg"
          title={t("SideBar.MenuTitle.Dashboard")}
          bbcolor="success"
          id="AM_DASHBOARD"
        />
        <AsideMenuItemWithSub
          to="#"
          title={t("Storefront.Column.Product")}
          bootstrapIcon="fas fa-box"
        >
          <AsideMenuItem
            to="/product/create-product/basic-information"
            title={t("AddNewProduct.Menu.Title.AddNewProduct")}
            id="storefront-productcreate"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/product/list"
            title="Product List"
            id="storefront-productlist"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/storefront/list"
            title={t("Storefront.Info.Storefront")}
            id="storefront-list"
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub
          to="#"
          title={t("Menu.Order")}
          bootstrapIcon="fas fa-clipboard"
        >
          <AsideMenuItem
            to="order/export"
            title={t("ExportOrder.Title")}
            id="order"
            hasBullet={true}
          />
          <AsideMenuItem
            to="order/list"
            title={t("OrderList.Title")}
            id="order-list"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/order/process"
            title={t("ExportOrderProcess.Title")}
            id="order-process"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/order/shippingHandover"
            title={t("Shipping.Handover.Aside")}
            id="shipping-handover"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/order/prePack"
            title={t("Value.Pack.Menu")}
            id="value-pack"
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
        <AsideMenuItem
          to="/ciq/list"
          duotoneIcon="/media/icons/duotune/communication/com002.svg"
          title={t("SideBar.MenuItem.CIQ")}
          fontIcon="bi-app-indicator"
          bbcount={ciqCount}
          bbcolor="danger"
          id="AM_CIQ"
        />
        <AsideMenuItem
          to="/handled-customer"
          duotoneIcon="/media/icons/duotune/communication/com010.svg"
          title={t("SideBar.MenuItem.HC")}
          bbcount={chatCount}
          bbcolor="success"
          id="AM_HC"
        />
        {/* {(clientID === "amx" || clientID === "Stagging") && (
          <AsideMenuItem
            to="/new-handled-customer"
            duotoneIcon="/media/icons/duotune/communication/com010.svg"
            title="<< New Chat >>"
            bbcount={countUnreadMessages + countAllChatUnreadMessages}
            bbcolor="success"
            id="AM_NEW_HC"
          />
        )} */}
        <AsideMenuItemWithSub
          to="/contact/contacts"
          title={t("SideBar.MenuItem.Contacts")}
          icon="/media/icons/duotune/communication/com005.svg"
        >
          <AsideMenuItem
            to="/contact/list"
            subMenu="/contact/contact-detail"
            title={t("SideBar.MenuItem.Customer")}
            id="AM_contact"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/contact/friendlist"
            title={t("SideBar.MenuItem.FriendList")}
            id="AM_friendlist"
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
        <AsideMenuItem
          to="/user-conversation"
          duotoneIcon="/media/icons/duotune/communication/com007.svg"
          title={t("SideBar.MenuItem.UC")}
          bbcount={countUserUnreadMessages}
          // bbcount={10}
          bbcolor="success"
          id="AM_UC"
        />
        <AsideMenuItemWithSub
          to="/campaign/campaigns"
          title={t("Campaign.Title.Campaign")}
          icon="/media/icons/customsvg/megaphone.svg"
          // bootstrapIcon="bi bi-megaphone-fill"
        >
          <AsideMenuItem
            to="/campaign/list"
            subMenu="/campaign/create"
            title={t("Campaign.Button.CreateCampaign")}
            id="AM_CreateCampaign"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/template/list"
            subMenu="/template/create"
            title={t("Campaign.Info.CreateTemplate")}
            id="AM_CreateTemplate"
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <hr className="opacity-0 py-2 my-0"></hr>
        <AsideMenuSetting />
        <AsideMenuLogout />
      </div>
      <div
      // style={{
      //   backgroundColor: "green",
      // }}
      >
        <UserBalance isSidebarMinimized={isSidebarMinimized as boolean} />
        <UserItem isSidebarMinimized={isSidebarMinimized as boolean} />
      </div>
    </div>
  );
}
