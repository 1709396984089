import clsx from "clsx";
import firebase from "firebase/compat/app";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  saveMessageMedia,
  requestReloadProductMessage,
} from "../../../../actions/chat";
import { MenuComponent } from "../../../../resources/assets/ts/components";
import {
  getIconChannelUrl,
  toAbsoluteUrl,
} from "../../../../resources/helpers/";
import "../../../../styles/css/color.css";
import "../../../../styles/css/dropdown.scss";
import { formatTemplate } from "../../../../util/Formatter";
import { readIdFromReferredData } from "../../../../util/ReadReferredData";
import Customer from "../../../models/Customer";
import Message, { InteractiveMessageComponent } from "../../../models/Message";
import User from "../../../models/User";
import * as chat from "../../../modules/chat/redux/ChatSlice";
import ChatFailedMenu from "./chat-menu/ChatFailedMenu";
import ChatMessageMenu from "./chat-menu/ChatMessageMenu";
import { MessageLocation } from "./chat-message/ChatLocation";
import { ChatFileView } from "./chat-modal/ChatFileView";
import ChatMessageReplyView from "./chat-replymessage/ChatMessageReplyView";
import { convertTextStyle } from "./chat-util/ChatUtil";
import ChatTime from "./chat-message/ChatTime";
import { MessageInvoice } from "./chat-replymessage/ChatMessageInvoice";
import { KTSVG } from "../../../../resources/helpers";
import * as LOG from "src/util/SDayslogger";

type MessageProps = {
  message: Message;
  isDrawer?: boolean;
  index: number;
  customer?: Customer;
  user?: User;
  messages: Message[];
  collaborationId: string;
  companyID: string;
  phoneNumber?: string;
  lastUserInteraction?: firebase.firestore.Timestamp;
};

const ChatMessage: FC<MessageProps> = (props) => {
  const { t } = useTranslation();
  const {
    message,
    lastUserInteraction,
    index,
    isDrawer,
    customer,
    user,
    messages,
    collaborationId,
    companyID,
    phoneNumber,
  } = props;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const [previousMedia, setPreviousMedia] = useState<string>("");
  const [nextMedia, setNextMedia] = useState<string>("");
  const [currentMedia, setCurrentMedia] = useState<string>(
    `kt_modal_${message?.messageType}_${message.id}`
  );
  const [replyMessageID, setReplyMessageID] = useState<any>("");
  const [showMsgInfo, setShowMsgInfo] = useState<boolean>(false);
  let listMediaUrl: string[] = [];

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 50);
  }, []);

  useEffect(() => {
    const findMessageReplyID = messages.find((obj) => obj.id === message.id);
    setReplyMessageID(findMessageReplyID?.replyMessageID?.id!);
    // setTimeout(() => {
    //   console.debug("replyMessageID", message.replyMessageID);
    // }, 3000);

    LOG.debug(
      "message data from chat message",
      messages.find((obj) => obj.id === message.id)
    );
    // console.log("message data from chat message", message);
    messages.forEach((obj) => {
      if (
        obj.messageType !== undefined &&
        (obj.messageType === "video" || obj.messageType === "image")
      ) {
        listMediaUrl.push(`kt_modal_${obj.messageType}_${obj.id}`);
      }
    });

    for (let index = 0; index < listMediaUrl.length; index++) {
      if (listMediaUrl[index] === currentMedia) {
        setNextMedia(listMediaUrl[index + 1]);
        if (index > 0) {
          setPreviousMedia(listMediaUrl[index - 1]);
        }
      }
    }
  }, []);

  useEffect(() => {
    const findMessageReplyID = messages.find((obj) => obj.id === message.id);
    const replyId = readIdFromReferredData(findMessageReplyID?.replyMessageID);
    console.debug(
      "get replyMessageID from function ReadRefferedData: ",
      replyId
    );
    setReplyMessageID(findMessageReplyID?.replyMessageID?.id ?? replyId);
  }, [messages]);

  useEffect(() => {
    messages.forEach((obj) => {
      if (
        obj.messageType !== undefined &&
        (obj.messageType === "video" || obj.messageType === "image")
      ) {
        listMediaUrl.push(`kt_modal_${obj.messageType}_${obj.id}`);
      }
    });

    for (let index = 0; index < listMediaUrl.length; index++) {
      if (listMediaUrl[index] === currentMedia) {
        setNextMedia(listMediaUrl[index + 1]);
        if (index > 0) {
          setPreviousMedia(listMediaUrl[index - 1]);
        }
      }
    }
  }, []);

  const state = message.customerModel !== null ? "info" : "primary";
  const templateAttr = {};

  let mediaFileName = message.filename;
  if (mediaFileName && mediaFileName?.length > 20) {
    mediaFileName = mediaFileName?.substring(0, 20) + "...";
  }

  let msgtype = "out";
  if (message.destination === "inbound") {
    msgtype = "in";
  }

  let bgChat = "cl-";
  let txChat = "black";
  if (msgtype !== "in") {
    bgChat = "cl-7days";
  } else {
    bgChat += message.channel.toString().toLowerCase();
    // sabunzone, tokopedia, whatsapp  : hitam
    if (
      message.channel.toString().toLowerCase() !== "sabunzone" &&
      message.channel.toString().toLowerCase() !== "tokopedia" &&
      message.channel.toString().toLowerCase() !== "whatsapp"
    ) {
      txChat = "white";
    }
  }

  const contentClass = `${isDrawer ? "" : "d-flex"} justify-content-${
    msgtype === "in" ? "start" : "end"
  } mb-3`;

  const contentType = `justify-content-${msgtype === "in" ? "start" : "end"}`;

  const saveMedia = () => {
    if (message.mediaUrl) {
      if (message.filename !== undefined && message.filename !== "") {
        return saveMessageMedia(message.mediaUrl, message.filename);
      } else {
        const filename = t("HC.File.Untitled");
        return saveMessageMedia(message.mediaUrl, message.filename);
      }
    } else {
      return alert(t("HC.Error.FailedUpload"));
    }
  };

  // Menu Open and Close Handler
  // Close Menu
  const closeMenuHandler = (menuId: string) => {
    console.log(menuId, "menuid");
    let message_menu = document.getElementById(menuId);
    if (message_menu) {
      message_menu?.classList.add("hide-child");
      message_menu?.click();
    }
  };

  // const renderReplyView = ({ children: ReactNode }) => {
  //   return (
  //     <ChatMessageReplyView
  //       bgChat={bgChat}
  //       replyMessageID={replyMessageID}
  //       customer={customer}
  //       phoneNumber={phoneNumber}
  //     >
  //       {children}
  //     </ChatMessageReplyView>
  //   );
  // };

  // console.log(message, "Message hry");

  const renderTemplate = (message: Message) => {
    const messageHeader: InteractiveMessageComponent | undefined = message
      ?.interactive?.components
      ? message?.interactive?.components?.find(
          (component) => component.type === "header"
        )
      : undefined;
    console.log("masuk sini ga", messageHeader);

    const renderTemplateOnly = () => {
      return (
        <div
          className={clsx({
            "mw-200px": isTabletOrMobile,
            "mw-lg-300px": !isTabletOrMobile,
          })}
        >
          <div
            style={{ color: txChat }}
            className={clsx(`p-5 rounded fw-bold ${bgChat}`)}
          >
            {message.header && (
              <div className="fw-bolder"> {message.header} </div>
            )}

            {!message.header && messageHeader && messageHeader.image && (
              <div className="d-flex">
                <img
                  alt=""
                  src={messageHeader.image}
                  className="rounded"
                  style={{
                    height: "auto",
                    width: "100%",
                  }}
                />
              </div>
            )}
            {!message.header && messageHeader && messageHeader.video && (
              <div className="d-flex">
                <video
                  controls
                  src={messageHeader.video}
                  className="rounded"
                  style={{ height: "auto", width: "100%" }}
                ></video>
              </div>
            )}

            {!message.header && messageHeader && messageHeader.document && (
              <div className="d-flex flex-fill bg-secondary search-toolbar mw-100 h-auto align-items-start image-input">
                <div className="w-30 m-2 align-self-center">
                  <i className="bi bi-file-earmark-text fs-3x text-dark"></i>
                </div>
                <div className="flex-grow-1 m-2 align-self-center break-word">
                  {messageHeader?.filename || "Header Document"}
                </div>
                <a
                  href={messageHeader.document}
                  target="_blank"
                  rel="noreferrer"
                  className="d-block w-30 m-5 align-self-center"
                >
                  <i className="bi bi-download fs-1 text-dark"></i>
                </a>
              </div>
            )}

            <div
              className="mt-2"
              dangerouslySetInnerHTML={{
                __html: convertTextStyle(message.textContent)
                  .replaceAll("\\n", "<br/>")
                  .replaceAll("\n", "<br/>"),
              }}
            />

            {message.footer && (
              <div className="fw-lighter"> {message.footer} </div>
            )}
          </div>

          {message.buttons && (
            <div className="mt-1 d-grid gap-1">
              {message.buttons.map((button, index) => (
                <button
                  key={index}
                  type="button"
                  style={{ outline: "none", border: "none" }}
                  className={clsx(
                    `px-5 py-3 rounded fw-bolder ${bgChat} text-center text-primary`
                  )}
                >
                  {button}
                </button>
              ))}
            </div>
          )}
        </div>
      );
    };

    return (
      <>
        {message.replyMessageID && (
          <ChatMessageReplyView
            bgChat={bgChat}
            replyMessageID={replyMessageID}
            customer={customer}
            phoneNumber={phoneNumber}
          >
            {renderTemplateOnly()}
          </ChatMessageReplyView>
        )}
        {!message.replyMessageID && renderTemplateOnly()}
      </>
    );
  };

  const renderUnsupported = () => {
    return (
      <div
        style={{ color: txChat }}
        className={clsx("p-5 rounded", `${bgChat}`, " fw-bold mw-lg-300px")}
      >
        <div>
          <span
            className="bi bi-clock-fill"
            style={{ color: txChat, paddingRight: "5px" }}
          />
          <span style={{ fontStyle: "italic" }}>
            {t("HC.Error.Unsupportedmsg")}
          </span>
        </div>
      </div>
    );
  };

  const renderAudio = () => {
    return (
      <div className={clsx(`${bgChat} pe-3 rounded`)}>
        <audio
          id={message.id}
          className={clsx(
            `audio-${bgChat}`,
            message.channel !== "7days" ? "audio-white" : ""
          )}
          // className={`audio-${bgChat} audio-white `}
          src={message.mediaUrl}
          data-testid="inboundchat-audio"
          controls
        ></audio>
      </div>
    );
  };

  const renderVideo = () => {
    return (
      <div
        className={clsx(
          "image-input",
          isTabletOrMobile ? "mw-200px" : "mw-lg-300px"
        )}
        // className="image-input mw-lg-300px"
        data-kt-image-input="true"
        data-bs-toggle="modal"
        data-bs-target={`#kt_modal_${message.messageType}_${message.id}`}
      >
        {/* begin::Preview existing video */}
        <div
          className="btn bi bi-play-circle fs-5x w-300px position-absolute top-50 start-50 translate-middle"
          style={{ color: "white" }}
          // style={{ color: "white", zIndex: 999 }}
        ></div>
        <video
          className={clsx(isTabletOrMobile ? "w-200px" : "w-300px")}
          // className="w-300px"
          src={message.mediaUrl}
          style={{ borderRadius: "8px" }}
          data-testid="inboundchat-video"
        ></video>
        {/* end::Preview existing video */}
      </div>
    );
  };

  const renderImage = () => {
    return (
      <div
        className={clsx(
          "image-input bg-opacity-25",
          isTabletOrMobile ? "w-200px" : "mw-lg-300px"
        )}
        // className="image-input bg-opacity-25 mw-lg-300px"
        data-kt-image-input="true"
        data-bs-toggle="modal"
        data-bs-target={`#kt_modal_${message.messageType}_${message.id}`}
        // onClick={openDialog}
      >
        {/* begin::Preview existing avatar */}
        <div
          className={clsx(
            "wrapper",
            isTabletOrMobile ? "mw-200px" : "mw-300px"
          )}
          // className="image-input-wrapper mw-300px"
          data-testid="inboundchat-image"
          style={{
            backgroundImage: `url('${message.mediaUrl}')`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          onMouseEnter={() => {
            let message_menu = document.getElementById(
              `message_menu_${message.id}`
            );
            if (message_menu) {
              message_menu?.classList.remove("hide-child");
            }
          }}
          onMouseLeave={() => closeMenuHandler(`message_menu_${message.id}`)}
        ></div>
        {/* end::Preview existing avatar */}
      </div>
    );
  };

  return (
    <div
      key={`message${index}`}
      className={clsx("d-flex", contentClass)}
      {...templateAttr}
    >
      {/* Inbound Avatar */}
      {msgtype === "in" && (
        <div
          className={clsx(
            "symbol",
            isTabletOrMobile ? "symbol-20px" : "symbol-35px"
          )}
          style={{ padding: isTabletOrMobile ? "2px" : "5px" }}
        >
          <img
            alt="Pic"
            src={toAbsoluteUrl(
              getIconChannelUrl(message.channel.toLowerCase())
            )}
            style={{
              objectFit: "cover",
            }}
          />
        </div>
      )}

      {/* Chat Message */}
      <div
        className="d-flex flex-column"
        style={{ position: "relative" }}
        onMouseEnter={() => {
          let message_menu = document.getElementById(
            `message_menu_${message.id}`
          );
          if (message_menu) {
            message_menu?.classList.remove("hide-child");
          }
        }}
        onMouseLeave={() => closeMenuHandler(`message_menu_${message.id}`)}
      >
        {/* Message Menu (Info & Reply) */}
        <div
          className="hide-child"
          id={`message_menu_${message.id}`}
          style={{ zIndex: 9999 }}
        >
          <div
            id={`message_menu_toggle_${message.id}`}
            className="message-menu fas fa-chevron-circle-down cursor-pointer fs-3"
            data-kt-menu-trigger="click"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="top-end"
            data-kt-menu-flip="bottom"
            data-testid={`message-menu-toggle-${message.id}`}
            style={{ color: "white" }}
          ></div>
          <ChatMessageMenu
            userMessage={message}
            destination={msgtype}
            onClickReply={() => {
              dispatch(chat.setReplyToMessage(message));
              console.log("click to reply messages", message);
            }}
          />
        </div>
        {/* Message Bubble */}
        <div
          id={`message_${message.id}`}
          className={clsx("d-flex flex-row", `${contentType}`)}
          data-testid={`inbound-message-${message.id}}`}
        >
          {/* Retry Message Menu if Status: "failed", undefined, "" */}
          {msgtype !== "in" &&
            (message.status === "failed" ||
              message.status === "" ||
              message.status === undefined) && (
              <ChatFailedMenu
                failedMessage={message}
                failedCustomer={customer}
                failedUserLastInteraction={lastUserInteraction}
                // failedCustomer= {message.customerModel}
                failedUser={user}
                failedCollaborationId={collaborationId}
                companyID={companyID}
              />
              // <ChatFailedMenu failedMessage={message} onDeleteMessage={deleteMessageHandler}/>
            )}
          {/* Text Message based on Message Type */}
          {/* Message Type: Unsupported */}
          {message.messageType === "unsupported" && (
            <>
              {message.replyMessageID && (
                <ChatMessageReplyView
                  bgChat={bgChat}
                  replyMessageID={replyMessageID}
                  customer={customer}
                  phoneNumber={phoneNumber}
                >
                  {renderUnsupported()}
                </ChatMessageReplyView>
              )}
              {!message.replyMessageID && renderUnsupported()}
            </>
          )}

          {/* Message Type: Text */}
          {(message.messageType === "text" ||
            message.messageType === "faq_liveagent" ||
            message.messageType === "button") && (
            <>
              {message.replyMessageID && (
                <ChatMessageReplyView
                  bgChat={bgChat}
                  replyMessageID={replyMessageID}
                  customer={customer}
                  phoneNumber={phoneNumber}
                >
                  <div
                    style={{ color: txChat }}
                    dangerouslySetInnerHTML={{
                      __html: convertTextStyle(
                        message.textContent.replaceAll("\n", "<br/>")
                      ),
                    }}
                    className={clsx(
                      "p-2 rounded fw-bold ",
                      `${bgChat}`,
                      isTabletOrMobile ? "mw-200px" : "mw-lg-300px"
                    )}
                  ></div>
                </ChatMessageReplyView>
              )}
              {!message.replyMessageID && (
                <div
                  style={{ color: txChat }}
                  dangerouslySetInnerHTML={{
                    __html: convertTextStyle(
                      message.textContent.replaceAll("\n", "<br/>")
                    ),
                  }}
                  className={clsx(
                    "p-5 rounded fw-bold ",
                    `${bgChat}`,
                    isTabletOrMobile ? "mw-200px" : "mw-lg-300px"
                  )}
                ></div>
              )}
            </>
          )}

          {/* Message Type: Document */}
          {message.messageType === "document" && (
            <>
              {message.replyMessageID && (
                <ChatMessageReplyView
                  bgChat={bgChat}
                  replyMessageID={replyMessageID}
                  customer={customer}
                  phoneNumber={phoneNumber}
                >
                  <div>document1</div>
                  <div
                    className={clsx(
                      "rounded",
                      `${bgChat}`,
                      "d-flex flex-column",
                      isTabletOrMobile ? "mw-300px" : "mw-lg-300px"
                    )}
                  >
                    <div
                      className={clsx(
                        "p-2 rounded",
                        `${bgChat}`,
                        "d-flex flex-row align-items-center bg-opacity-25"
                      )}
                      title={message.filename}
                    >
                      <div
                        className="bi bi-file-earmark-text fs-3x"
                        style={{ color: txChat }}
                      ></div>
                      <div
                        className="container-sm"
                        style={{ paddingLeft: "5px", color: txChat }}
                      >
                        {mediaFileName}
                      </div>
                      <div
                        className="d-flex flex-column justify-content-end"
                        style={{ width: "70px" }}
                      >
                        <div
                          className="btn bi bi-download fs-3"
                          onClick={saveMedia}
                          style={{
                            color: txChat,
                            paddingTop: "5px",
                            paddingLeft: "0px",
                            paddingRight: "0px",
                            paddingBottom: "0px",
                          }}
                        ></div>
                        <div
                          className="text-center"
                          style={{ fontSize: "10px", color: txChat }}
                        >
                          {message.filesize}
                        </div>
                      </div>
                    </div>
                    {message.textContent && message.textContent !== "" && (
                      <div
                        className={clsx(
                          "p-3 rounded",
                          `${bgChat}`,
                          "fluid align-items-center"
                        )}
                        style={{ color: txChat }}
                        dangerouslySetInnerHTML={{
                          __html: convertTextStyle(
                            message.textContent
                          ).replaceAll("\n", "<br/>"),
                        }}
                      ></div>
                    )}
                  </div>
                </ChatMessageReplyView>
              )}
              {!message.replyMessageID && (
                <div
                  className={clsx(
                    `p-2 rounded d-flex flex-column ${bgChat}`,
                    isTabletOrMobile ? "mw-300px" : "mw-lg-330px"
                  )}
                >
                  <MessageInvoice
                    message={message}
                    bgChat={bgChat}
                    txChat={txChat}
                  />
                  {message.textContent && message.textContent !== "" && (
                    <div
                      className={clsx(
                        "p-3 rounded fluid align-items-center",
                        `${bgChat}`
                      )}
                      style={{ color: txChat }}
                      dangerouslySetInnerHTML={{
                        __html: convertTextStyle(
                          message.textContent
                        ).replaceAll("\n", "<br/>"),
                      }}
                    ></div>
                  )}
                </div>
              )}
            </>
          )}

          {/* Message Type: Order */}
          {message.messageType === "order" && (
            <div
              className={clsx(
                `p-2 rounded ${bgChat} d-flex flex-column`,
                isTabletOrMobile ? "mw-300px" : "mw-lg-330px"
              )}
            >
              <MessageInvoice
                message={message}
                bgChat={bgChat}
                txChat={txChat}
              />
              {message.textContent && message.textContent !== "" && (
                <div
                  className={clsx(
                    "p-3 rounded fluid align-items-center",
                    `${bgChat}`
                  )}
                  style={{ color: txChat }}
                  dangerouslySetInnerHTML={{
                    __html: convertTextStyle(message.textContent).replaceAll(
                      "\n",
                      "<br/>"
                    ),
                  }}
                ></div>
              )}
            </div>
          )}

          {/* Message Type: Product */}
          {message.messageType === "product" && (
            <>
              {message.replyMessageID && (
                <ChatMessageReplyView
                  bgChat={bgChat}
                  replyMessageID={replyMessageID}
                  customer={customer}
                  phoneNumber={phoneNumber}
                >
                  <div
                    className={clsx(
                      "rounded",
                      `${bgChat}`,
                      "d-flex flex-column",
                      isTabletOrMobile ? "mw-300px" : "mw-lg-300px"
                    )}
                  >
                    <div
                      className={clsx(
                        "p-2 rounded",
                        `${bgChat}`,
                        "d-flex flex-row align-items-center bg-opacity-25"
                      )}
                      title={message.filename}
                    >
                      <div
                        className="bi bi-file-earmark-text fs-3x"
                        style={{ color: txChat }}
                      ></div>
                      <div
                        className="container-sm"
                        style={{ paddingLeft: "5px", color: txChat }}
                      >
                        {mediaFileName}
                      </div>
                      <div
                        className="d-flex flex-column justify-content-end"
                        style={{ width: "70px" }}
                      >
                        <div
                          className="btn bi bi-download fs-3"
                          onClick={saveMedia}
                          style={{
                            color: txChat,
                            paddingTop: "5px",
                            paddingLeft: "0px",
                            paddingRight: "0px",
                            paddingBottom: "0px",
                          }}
                        ></div>
                        <div
                          className="text-center"
                          style={{ fontSize: "10px", color: txChat }}
                        >
                          {message.filesize}
                        </div>
                      </div>
                    </div>
                    {message.textContent && message.textContent !== "" && (
                      <div
                        className={clsx(
                          "p-3 rounded",
                          `${bgChat}`,
                          "fluid align-items-center"
                        )}
                        style={{ color: txChat }}
                        dangerouslySetInnerHTML={{
                          __html: convertTextStyle(
                            message.textContent
                          ).replaceAll("\n", "<br/>"),
                        }}
                      ></div>
                    )}
                  </div>
                </ChatMessageReplyView>
              )}
              {!message.replyMessageID && (
                <>
                  {message.textContent && message.textContent !== "" ? (
                    <div
                      className={clsx(
                        "p-2 rounded",
                        `${bgChat}`,
                        "d-flex flex-column",
                        isTabletOrMobile ? "mw-300px" : "mw-lg-300px"
                      )}
                    >
                      <div
                        className={clsx(
                          "p-3 rounded",
                          `${bgChat}`,
                          "fluid align-items-center"
                        )}
                        style={{ color: txChat }}
                        dangerouslySetInnerHTML={{
                          __html: convertTextStyle(
                            message.textContent
                          ).replaceAll("\n", "<br/>"),
                        }}
                      ></div>
                    </div>
                  ) : (
                    <div
                      id={`message_${message.id}`}
                      className={clsx("d-flex flex-row mb-2", `${contentType}`)}
                      data-testid={`inbound-message-${message.id}}`}
                    >
                      <div
                        className={clsx(
                          "p-2 rounded",
                          `${bgChat}`,
                          "d-flex flex-column",
                          isTabletOrMobile ? "mw-300px" : "mw-lg-250px"
                        )}
                      >
                        {/* Container for the message content */}
                        <div
                          className={clsx(
                            "p-2 rounded",
                            `${bgChat}`,
                            "d-flex flex-row align-items-center bg-opacity-25 col-gap-2 pe-4"
                          )}
                        >
                          {message?.product &&
                          message.product.displayName !== "" &&
                          message.product.displayPrice !== "" ? (
                            <>
                              {/* Left container for media content */}
                              <div
                                className="d-flex m-0 p-0"
                                style={{ color: txChat }}
                              >
                                {message?.product?.imageUrl && (
                                  <img
                                    src={message.product.imageUrl}
                                    alt="foto"
                                    className="rounded"
                                    width={100}
                                    height={100}
                                  />
                                )}
                              </div>
                              {/* Right container for product information */}
                              <div className="d-flex flex-column">
                                <div className="font-weight-bold mb-2 fs-5">
                                  {message?.product?.displayName}
                                </div>
                                <div
                                  className="fs-5"
                                  style={{ fontWeight: "bolder" }}
                                >
                                  {message?.product?.displayPrice
                                    ? `Rp. ${message.product.displayPrice
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
                                    : ""}
                                </div>
                              </div>
                            </>
                          ) : (
                            <div
                              className="d-flex flex-column align-items-center justify-content-center m-0 px-1 py-2 row-gap-1 cursor-pointer"
                              onClick={async () => {
                                let room = await message.room?.get();
                                let collab =
                                  await message.room?.parent?.parent?.get();
                                let account = await room?.data()?.account.get();
                                if (room && account && collab) {
                                  let jsonRequest = {
                                    account: {
                                      id: account.id,
                                      ...account.data(),
                                    },
                                    item_id: [
                                      Number(
                                        message?.product?.marketplaceProductId
                                      ),
                                    ],
                                    message_id: message.id,
                                  };
                                  await requestReloadProductMessage(
                                    jsonRequest
                                  );
                                  dispatch(chat.setSelectedRoom(""));
                                  dispatch(chat.setSelectedRoom(room.id));
                                }
                              }}
                            >
                              <KTSVG
                                path="/media/icons/etc.svg"
                                className="svg-icon-1 position-absolute ms-6"
                              />
                              <p className="p-0 m-0 text-secondary text-center">
                                {t("Shopee.Message.NoProduct")}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {/* Message Type: Image */}
          {(message.messageType === "image" ||
            message.messageType === "video") && (
            //bubble chat
            <div
              className={clsx(`rounded ${bgChat} d-flex flex-column`, {
                "px-5 pt-5 pb-3":
                  message.messageType === "video" && !message.replyMessageID,
                "p-5":
                  message.messageType === "image" && !message.replyMessageID,
              })}
            >
              {message.replyMessageID && (
                <ChatMessageReplyView
                  bgChat={bgChat}
                  replyMessageID={replyMessageID}
                  customer={customer}
                  phoneNumber={phoneNumber}
                >
                  {message.messageType === "video" && renderVideo()}
                  {message.messageType === "image" && renderImage()}
                </ChatMessageReplyView>
              )}
              {!message.replyMessageID &&
                message.messageType === "video" &&
                renderVideo()}
              {!message.replyMessageID &&
                message.messageType === "image" &&
                renderImage()}

              {/***
               * Caption
               */}
              {message.textContent && message.textContent !== "" && (
                <div
                  className={clsx(
                    `p-3 rounded ${bgChat} fluid align-items-center`,
                    {
                      "mw-200px": isTabletOrMobile,
                      "mw-300px": !isTabletOrMobile,
                    }
                  )}
                  style={{ color: txChat }}
                  dangerouslySetInnerHTML={{
                    __html: convertTextStyle(message.textContent).replaceAll(
                      "\n",
                      "<br/>"
                    ),
                  }}
                ></div>
              )}
              {/***
               * End Of Caption
               */}

              {message.mediaUrl && message.id && message.filename && (
                <ChatFileView
                  messageType={message.messageType}
                  mediaURL={message.mediaUrl}
                  messageId={message.id}
                  mediaName={message.filename}
                  previousMedia={previousMedia}
                  nextMedia={nextMedia}
                  currentMedia={currentMedia}
                />
              )}
            </div>
            //End Of Bubble chat
          )}

          {/* Message Type: Audio */}
          {message.messageType === "audio" && (
            <>
              {message.replyMessageID && (
                <ChatMessageReplyView
                  bgChat={bgChat}
                  replyMessageID={replyMessageID}
                  customer={customer}
                  phoneNumber={phoneNumber}
                >
                  {renderAudio()}
                </ChatMessageReplyView>
              )}
              {!message.replyMessageID && renderAudio()}
            </>
          )}

          {/* Message Type: Location */}
          {message.messageType === "location" && (
            <>
              {message.replyMessageID && (
                <ChatMessageReplyView
                  bgChat={bgChat}
                  replyMessageID={replyMessageID}
                  customer={customer}
                  phoneNumber={phoneNumber}
                >
                  <div
                    style={{ color: txChat }}
                    className={clsx(
                      "fw-bold"
                      // `${bgChat}`,
                      // "bg-primary",
                      // isTabletOrMobile ? "w-200px" : "w-300px"
                      // "  w-300px"
                    )}
                  >
                    {/* Map */}
                    <a
                      href={`https://maps.google.com/maps?q=${message.location?.latitude},${message.location?.longitude}&z=17`}
                      data-testid="inboundchat-location"
                    >
                      <div
                        className={isTabletOrMobile ? "mw-200px" : "mw-300px"}
                      >
                        <MessageLocation item={message} />
                      </div>
                    </a>

                    {/* Caption : Location Name + Address*/}
                    <div
                      className={clsx(
                        "rounded",
                        `${bgChat}`,
                        "fluid align-items-center"
                      )}
                      style={{ color: txChat, paddingTop: "5px" }}
                    >
                      {message.locationName && (
                        <div data-testid="inboundchat-locationname">
                          <span
                            className="bi bi-geo-alt-fill"
                            style={{ paddingRight: "5px" }}
                          />
                          {message.locationName}
                        </div>
                      )}
                      {message.locationAddress && (
                        <div>{message.locationAddress}</div>
                      )}
                    </div>
                  </div>
                </ChatMessageReplyView>
              )}
              {!message.replyMessageID && (
                <div
                  style={{ color: txChat }}
                  className={clsx(
                    "p-5 rounded fw-bold",
                    `${bgChat}`,
                    isTabletOrMobile ? "w-200px" : "w-300px"
                    // "  w-300px"
                  )}
                >
                  {/* Map */}
                  <a
                    href={`https://maps.google.com/maps?q=${message.location?.latitude},${message.location?.longitude}&z=17`}
                    data-testid="inboundchat-location"
                  >
                    <div className="w-300px">
                      <MessageLocation item={message} />
                    </div>
                  </a>

                  {/* Caption : Location Name + Address*/}
                  <div
                    className={clsx(
                      "rounded",
                      `${bgChat}`,
                      "fluid align-items-center"
                    )}
                    style={{ color: txChat, paddingTop: "5px" }}
                  >
                    {message.locationName && (
                      <div data-testid="inboundchat-locationname">
                        <span
                          className="bi bi-geo-alt-fill"
                          style={{ paddingRight: "5px" }}
                        />
                        {message.locationName}
                      </div>
                    )}
                    {message.locationAddress && (
                      <div>{message.locationAddress}</div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}

          {/* Message Type: Template */}
          {(message.messageType === "template" ||
            message.messageType === "interactive") &&
            renderTemplate(message)}
        </div>

        {/* Message Timestamp and Status >> Status Message: submitted,sent,delivered, read , failed (search for retry message) */}
        <div
          className={clsx("d-flex ", {
            "ms-3": msgtype !== "in",
            "justify-content-start": msgtype === "in",
            "justify-content-end": msgtype !== "in",
          })}
        >
          <span className="d-flex flex-row text-muted fs-7 mb-1">
            {msgtype !== "in" && (
              <i
                className={clsx("bi", "bi bi-check2-all text-primary", {
                  "fs-3": !isTabletOrMobile,
                  "fs-1": isTabletOrMobile,
                  "bi-clock": message.status === "submitted",
                  "bi-check2": message.status === "sent",
                  "bi-check2-all": message.status === "delivered",
                  "bi-check2-all text-primary": message.status === "read",
                })}
              ></i>
            )}

            {ChatTime(message.createdAt.seconds)}
          </span>
        </div>
      </div>

      {/* Outbound Avatar */}
      {msgtype !== "in" && (
        <div
          // className="symbol  symbol-35px symbol-circle"
          className={clsx("symbol", {
            "symbol-20px": isTabletOrMobile,
            "symbol-35px": !isTabletOrMobile,
          })}
          style={{ padding: isTabletOrMobile ? "2px" : "5px" }}
        >
          <img
            alt="Pic"
            src={toAbsoluteUrl(
              getIconChannelUrl(message.channel.toLowerCase())
            )}
            style={{
              objectFit: "cover",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ChatMessage;
