/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useQueryRequest } from "./QueryRequestProvider";
import { useDispatch, useSelector } from "react-redux";
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
} from "../../../../../../resources/helpers";
import Product, { ProductQueryResponse } from "../../../../../models/Product"
import { RootState } from "../../../../../../setup/redux/store";
import { getProducts, getProductCount } from "src/db/serviceProduct"
import * as ProductListRedux from "../../../../../modules/product/productList/ProductListSlice";
import { Search } from "src/resources/partials";
import * as Log from "src/util/SDayslogger"
import * as localStorage from "src/app/modules/localstorage"

const QueryResponseContext = createResponseContext<Product>(initialQueryResponse);
const QueryResponseProvider: FC = ({ children }) => {
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(0);
  let listProductData = useSelector(
    (state: RootState) => state.ProductList.listProduct
  ); //list CIQ
  const currentProductPage = useSelector(
    (state: RootState) => state.ProductList.currentPageProduct
  ); //list CIQ
  let ProductQueryResponse: ProductQueryResponse;

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  useEffect(() => {
    Log.debug("Product List QueryResponseProvider >>>>");
    setCounter(listProductData.length);
  }, [listProductData]);

  

  //:Promise<{data:Product[]}>
  const fetchProduct = async () => {
    
    Log.debug(`product fetch: currentProductPage ${currentProductPage} statePage ${state.page}` )
  
    const localClient = localStorage.getItemLC(localStorage.LCName.Client)
    if(!localClient){
      const product:Product[] = []
      return Promise.resolve({data:product, total:0})
    }
    const clientId = localClient.id

    
    const totalProduct = await getProductCount(clientId);

    //cek redux
    // if (!listProductData || listProductData.length <= 0 || currentProductPage!==state.page) {
    const lastDoc = listProductData && listProductData?.length>0? listProductData[listProductData?.length-1] : undefined
    const data = await getProducts(
      clientId,
      state.sort,
      state.order,
      state.items_per_page,
      lastDoc?.id
    );

    // if (data && data.length > 0) {
      dispatch(ProductListRedux.setListProduct(data));
    // }
    // } else {
    //   data = listProductData;
    // }

    
    dispatch(ProductListRedux.setCurrentPageProduct(state.page))
    dispatch(
      ProductListRedux.setCountProduct(totalProduct)
    );

    // if (state.search && state.search !== "") {
    // } else {
      
    // }

    ProductQueryResponse = {
      data,
    };
    return Promise.resolve(ProductQueryResponse);
  }

  const {data:response, isFetching, error, refetch} = useQuery(
    ["products", state.page], () => fetchProduct(), {
      keepPreviousData: true,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: true,
    }
  )


  return (
    <QueryResponseContext.Provider
      value={{ isLoading: isFetching, refetch, response, query }}
    >
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }
  // console.log("response storefront "+JSON.stringify(response));

  return response?.data || [];
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  };

  const { response } = useQueryResponse();
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState;
  }

  return response.payload.pagination;
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
};
